"use strict";

export function initInScope($scope) {
    let $spacingItems = $scope.find('.js-modal-open-spacing');

    $scope.find('.modal').on('show.bs.modal', () => {
        let scrollbarWidth = getScrollbarWidth();
        createSpacing($spacingItems, scrollbarWidth);
    });

    $scope.find('.modal').on('hidden.bs.modal', () => {
        createSpacing($spacingItems, '');
    });
}

function createSpacing($items, spacing) {
    $items.each(function() {
        let $item = $(this);
        let modalOpenSpacingStyle = $item.attr('data-modal-open-spacing-style');

        if (!modalOpenSpacingStyle) {
            console.warn("data-modal-open-spacing-style: NOT DEFINED");
        } else {
            $item.css(modalOpenSpacingStyle, spacing);
        }
    });
}

function getScrollbarWidth() {
    let scrollDiv = document.createElement('div');
    document.body.appendChild(scrollDiv);
    scrollDiv.classList.add('modal-scrollbar-measure');
    const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    
    return scrollbarWidth;
}