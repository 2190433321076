import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    const $teasers = $('.js-person-teaser');

    if($teasers.length){
        add(
            $teasers,
            calculateAnimationProgress,
            setAnimationProgress
        );
    }
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const id = $(element).data('person-teaser-id');
    const factor = id % 4;
    let start = 0;
    if(window.matchMedia('(max-width: 767px)').matches){
        start = (window.innerHeight / 8 * 7);
    }else{
        start = (window.innerHeight / 8 * 8) - (factor * 75);
    }
    const end = window.innerHeight / 2;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    const transformation = Math.abs(((progress -1) * 100) * - 1);
    element.style.opacity = progress;
    element.style.transform = `translateY(${transformation}px)`
}