'use strict'
import { CountUp } from 'countup.js';
import { onEnterViewPort } from '@elements/viewport-utils'

export function initInScope($scope) {
    const $countUps = $scope.find('.js-count-up');

    $countUps.each((_, el) => {
        el.innerHTML = Math.abs(parseInt(el.innerHTML) - 15);
    })

    if($countUps.length){
        onEnterViewPort($countUps, ($element) => {
            const
                number  = $element.data('count-up-number'),
                countUp = new CountUp($element[0], number, {
                    startVal : Math.abs(number -15)
                })

            countUp.start();
        })
    }
}
